<template>
	<div class="login-container">
		<el-form ref="form" label-width="0px" :model="form" :rules="rules">
			<el-form-item prop="username">
				<el-input v-model="form.username" placeholder="请输入手机号码" />
			</el-form-item>
			<el-form-item prop="password">
				<el-input type="password" v-model="form.password" placeholder="请输入密码" />
			</el-form-item>
			<el-form-item prop="code">
				<el-input v-model="form.code" auto-complete="off" placeholder="验证码" style="width: 63%"> </el-input>
				<div class="login-code">
					<img :src="codeUrl" @click="getCode" />
				</div>
			</el-form-item>
			<el-form-item>
				<div class="forget-box">
					<router-link class="link" :to="{ path: '/forgetPassword' }" tag="span">忘记密码?</router-link>
				</div>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm">登录</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { getToken } from '@/utils/auth';
import Cookies from 'js-cookie';
import { encrypt, decrypt, generateShortUUID } from '@/utils/jsencrypt';
import { getinspectorMy } from '@/api/bm/inspectorScoreDetail.js';
import { getCodeImg } from '@/api/login';
import CryptoJS from 'crypto-js';

export default {
	name: 'login',
	data() {
		let checkUsername = (rule, value, callback) => {
			if (!value) {
				return callback(new Error('请输入手机号'));
			}
			if (!/^1[3456789]\d{9}$/.test(value)) {
				callback(new Error('手机号格式不正确'));
			} else {
				callback();
			}
		};

		return {
			form: { username: undefined, password: undefined, rememberMe: undefined },
			redirect: undefined,
			rules: {
				username: [
					// {validator: checkUsername, trigger: 'blur'},
				],
				password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
				code: [{ required: true, trigger: 'change', message: '验证码不能为空' }],
			},
			codeUrl: undefined,
		};
	},
	watch: {
		$route: {
			handler: function(route) {
				console.log(route.query);
				console.log(route.query.redirect);
				this.redirect = route.query && route.query.redirect;
			},
			immediate: true,
		},
	},
	methods: {
		getCode() {
			getCodeImg().then((res) => {
				this.codeUrl = 'data:image/gif;base64,' + res.img;
				this.form.uuid = res.uuid;
			});
		},
		// 加密
		encryptInformation(word, keyStr, ivStr) {
			const UUID = generateShortUUID(); // 随机生成八位UUID
			let key = CryptoJS.enc.Utf8.parse(`gYagjGhi${UUID}`); // 中与后台一样  密码
			let iv = CryptoJS.enc.Utf8.parse(`dO6sde8Mcr8HMRl9`); // 中与后台一样

			const srcs = CryptoJS.enc.Utf8.parse(word);
			var encrypted = CryptoJS.AES.encrypt(srcs, key, {
				iv: iv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.Pkcs7,
			});

			return UUID + CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
		},
		login() {
			if (this.rememberMe) {
				Cookies.set('username', this.form.username, { expires: 30 });
				Cookies.set('password', encrypt(this.form.password), {
					expires: 30,
				});
				Cookies.set('rememberMe', this.form.rememberMe, { expires: 30 });
			} else {
				Cookies.remove('username');
				Cookies.remove('password');
				Cookies.remove('rememberMe');
			}
			console.log(this.form);
			const params = {
				...this.form,
				username: this.encryptInformation(this.form.username),
				password: this.encryptInformation(this.form.password),
			};
			this.$store
				.dispatch('Login', params)
				.then(() => {
					this.reload();
				})
				.catch(() => {
					this.getCode();
				});
		},
		reload() {
			console.log(this.redirect);
			if (this.$route.query.id) {
				this.$router.push({
					path: '/signup',
					query: {
						id: this.$route.query.id,
						jobNumber: this.$route.query.jobNumber,
					},
				});
			} else {
				this.$router.push({ path: this.redirect || '/' });
				console.log('...');
			}

			// getinspectorMy().then((res) => {
			//   console.log("登录时获取工号", res.data.jobNumber);
			//   if(res.code == '200'){
			//     localStorage.setItem('myJobNumber', res.data.jobNumber)
			//   }
			// });
		},
		getCookie() {
			const username = Cookies.get('username');
			const password = Cookies.get('password');
			const rememberMe = Cookies.get('rememberMe');
			this.form = {
				username: username === undefined ? this.form.username : username,
				password: password === undefined ? this.form.password : decrypt(password),
				rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
			};
		},
		submitForm() {
			this.$refs['form'].validate((valid) => {
				if (valid) {
					this.login();
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
	},
	created() {
		this.getCookie();
		this.getCode();
		if (getToken() != null) {
			this.reload();
		}
	},
};
</script>

<style lang="scss">
.login-container {
	.forget-box {
		width: 100%;
		height: 100%;
		position: relative;
		height: 40px;

		.link {
			position: absolute;
			right: 0;
			top: -20px;
			cursor: pointer;
		}
	}
}
.login-code {
	width: 33%;
	height: 38px;
	float: right;
	img {
		cursor: pointer;
		vertical-align: middle;
	}
}
</style>
